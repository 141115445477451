import React from 'react';
import Slider from 'react-slick';
import cx from 'classnames';
import qs from 'query-string';
import './App.css';

const SampleNextArrow = (props) => {
  const { className, onClick } = props;
  return (
    <div
      className={className + 'right-arrow-3 wslider-arrow-right'}
      onClick={onClick}
    >
      <img
        src="https://refind-api.paralect.com/landing/images/arrow-2.svg"
        alt=""
        className="image-16"
      />
    </div>
  );
};

const SamplePrevArrow = (props) => {
  const { className, onClick } = props;
  return (
    <div
      className={className + 'left-arrow-3 wslider-arrow-left'}
      onClick={onClick}
    >
      <img
        src="https://refind-api.paralect.com/landing/images/arrow-1.svg"
        alt=""
        className="image-16"
      />
    </div>
  );
};

const slickProps = {
  dots: false,
  infinite: true,
  speed: 500,
  slidesToShow: 5,
  slidesToScroll: 5,
  nextArrow: <SampleNextArrow />,
  prevArrow: <SamplePrevArrow />,
  className: 'slider-3',
  responsive: [
    {
      breakpoint: 1600,
      settings: {
        slidesToShow: 4,
        slidesToScroll: 4,
      },
    },
    {
      breakpoint: 1300,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 3,
      },
    },
    {
      breakpoint: 1020,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2,
      },
    },
    {
      breakpoint: 750,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
      },
    },
  ],
};

function Item({ item: { imageUrl, name, supplier, category, fileName } }) {
  const queryString = qs.stringify({
    name,
    fileName,
    category,
  });

  return (
    <a
      href={`/suppliers/${supplier.toLowerCase().split(' ').join('-')}?${queryString}`}
      class="link-block-2 w-inline-block"
    >
      <div className="card-1">
        <div className="div-block-17">
          <img src={imageUrl} alt="" />
        </div>
        <div className="b-line"></div>
        <div className="name-price-block">
          <div className="name">{name}</div>
        </div>
        <div class="text-block-10">{supplier}</div>
      </div>
    </a>
  );
}

class App extends React.Component {
  constructor(props) {
    super(props);

    const urlParams = new URLSearchParams(window.location.search);
    const showPrev = urlParams.get('showPrev');

    let value = null;
    let items = null;

    if (showPrev === '1') {
      const itemsJson = localStorage.getItem('items');

      value = localStorage.getItem('value');
      items = itemsJson ? JSON.parse(itemsJson) : null;
    }

    this.state = {
      isLoading: false,
      items: items || null,
      value: value || null,
      isShowAll: false,
      isError: false,
    };
  }

  getItems = async (file) => {
    try {
      this.setState({
        isLoading: true,
        items: null,
        value: null,
        isError: false,
      });

      const formData = new FormData();
      formData.append('file', file);

      const response = await fetch('https://refind-api.paralect.com/products', {
        method: 'POST',
        body: formData,
      });
      const items = await response.json();

      const fileReader = new FileReader();

      fileReader.onload = (e) => {
        localStorage.setItem('value', e.target.result);
        localStorage.setItem('items', JSON.stringify(items));

        this.setState({
          value: e.target.result,
          items,
          isShowAll: false,
          isLoading: false,
        });
        this.slider.slickGoTo(0);
      };

      fileReader.readAsDataURL(file);
    } catch (error) {
      this.setState({
        isLoading: false,
        items: null,
        value: null,
        isError: true,
      });
    }
  };

  onFileUpload = async ({ target }) => {
    const [file] = target.files;
    if (!file) {
      return;
    }

    this.getItems(file);
  };

  getItemsByPreview = async () => {
    const response = await fetch(
      'https://refind-api.paralect.com/static/androneda-29-table-lamp.jpg'
    );
    const myBlob = await response.blob();

    this.getItems(myBlob);
  };

  render() {
    const { items, isLoading, value, isShowAll, isError } = this.state;

    return (
      <>
        <div className="try-it-block">
          <h1 className="heading-4-copy">Try our Visual Search</h1>
        </div>
        {isLoading ? (
          <div class="lds-default">
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
          </div>
        ) : (
          <div className={cx('button-block')}>
            {value ? (
              <>
                <label htmlFor="file">
                  <div className="uploaded-image pointer">
                    <div className="hover-img">
                      <img
                        src="https://refind-api.paralect.com/landing/images/Group-3.svg"
                        alt=""
                        className="image-29"
                      />
                    </div>
                    <img
                      className="ref-img"
                      src={value}
                      sizes="(max-width: 479px) 100vw, 598px"
                      alt=""
                    />
                  </div>
                  <div className="upl-title">Your reference image</div>
                </label>
              </>
            ) : (
              <>
                <label htmlFor="file" className="refilled-button _1st pointer">
                  <img
                    src="https://refind-api.paralect.com/landing/images/Group-3.svg"
                    alt=""
                    className="image-29"
                  />
                  <div className="text-block-9">Upload Image</div>
                </label>
                <img
                  src="https://refind-api.paralect.com/landing/images/or.svg"
                  alt=""
                  className="image-24"
                />
                <div
                  className="refilled-button pointer"
                  onClick={this.getItemsByPreview}
                >
                  <div className="text-block-9">Find lighting like this</div>
                  <img
                    src="https://refind-api.paralect.com/landing/images/preview.png"
                    alt=""
                    className="image-29-copy"
                  />
                </div>

                {isError && (
                  <div className="text-block-5 error-text">
                    File size must be 20 mb or bellow
                  </div>
                )}
              </>
            )}
            <input
              type="file"
              accept="image/x-png,image/gif,image/jpeg"
              id="file"
              style={{ display: 'none' }}
              onChange={this.onFileUpload}
            />
          </div>
        )}

        {items && items.length && (
          <>
            {!isShowAll ? (
              <>
                <div className="mask-2">
                  <Slider
                    {...slickProps}
                    ref={(slider) => (this.slider = slider)}
                  >
                    {items.map((item) => (
                      <Item item={item} key={Math.random()} />
                    ))}
                  </Slider>
                </div>
                <div className="pagination">
                  <a
                    className="button-2 w-button"
                    onClick={() => this.setState({ isShowAll: true })}
                  >
                    Expand All
                  </a>
                </div>
              </>
            ) : (
              <div className="list">
                {items.map((item) => (
                  <Item item={item} key={Math.random()} />
                ))}
              </div>
            )}
          </>
        )}
      </>
    );
  }
}

export default App;
