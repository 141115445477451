import React from 'react';
import Slider from 'react-slick';
import cx from 'classnames';
import qs from 'query-string';
import './App.css';

const SampleNextArrow = (props) => {
  const { className, onClick } = props;
  return (
    <div
      className={className + 'right-arrow-3 wslider-arrow-right'}
      onClick={onClick}
    >
      <img
        src="https://refind-api.paralect.com/landing/images/arrow-2.svg"
        alt=""
        className="image-16"
      />
    </div>
  );
};

const SamplePrevArrow = (props) => {
  const { className, onClick } = props;
  return (
    <div
      className={className + 'left-arrow-3 wslider-arrow-left'}
      onClick={onClick}
    >
      <img
        src="https://refind-api.paralect.com/landing/images/arrow-1.svg"
        alt=""
        className="image-16"
      />
    </div>
  );
};

const slickProps = {
  dots: false,
  infinite: true,
  speed: 500,
  slidesToShow: 5,
  slidesToScroll: 5,
  nextArrow: <SampleNextArrow />,
  prevArrow: <SamplePrevArrow />,
  className: 'slider-3-sup',
  responsive: [
    {
      breakpoint: 1600,
      settings: {
        slidesToShow: 4,
        slidesToScroll: 4,
      },
    },
    {
      breakpoint: 1300,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 3,
      },
    },
    {
      breakpoint: 1020,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2,
      },
    },
    {
      breakpoint: 750,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
      },
    },
  ],
};

function Item({ item: { imageUrl, name, supplier, category, fileName } }) {
  const queryString = qs.stringify({
    name,
    fileName,
    category,
  });

  return (
    <a
      href={`/suppliers/${supplier
        .toLowerCase()
        .split(' ')
        .join('-')}?${queryString}`}
      class="link-block-2 w-inline-block"
    >
      <div className="card-1">
        <div className="div-block-17">
          <img src={imageUrl} alt="" />
        </div>
        <div className="b-line"></div>
        <div className="name-price-block">
          <div className="name">{name}</div>
        </div>
        <div class="text-block-10">{supplier}</div>
      </div>
    </a>
  );
}

class App extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: true,
      items: null,
      isShowAll: false,
      isError: false,
    };
  }

  getItems = async () => {
    try {
      this.setState({
        isLoading: true,
        isError: false,
      });

      const supplier = window.location.pathname
        .match(/\/suppliers\/(.+)/)
        .pop();
      const response = await fetch(
        `https://refind-api.paralect.com/products/${supplier}`,
        {
          method: 'get',
        }
      );

      const items = await response.json();

      this.setState({
        isLoading: false,
        items,
      });
    } catch (error) {
      this.setState({
        isLoading: false,
        items: null,
        isError: true,
      });
    }
  };

  componentDidMount() {
    this.getItems();
  }

  render() {
    const { items, isLoading, isShowAll } = this.state;

    return isLoading ? (
      <div class="lds-default">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
    ) : (
      items && items.length && (
        <>
          {!isShowAll ? (
            <>
              <div className="mask-2">
                <Slider
                  {...slickProps}
                  ref={(slider) => (this.slider = slider)}
                >
                  {items.map((item) => (
                    <Item item={item} key={Math.random()} />
                  ))}
                </Slider>
              </div>
              <div className="pagination-sup">
                <a
                  className="button-2-sup w-button"
                  onClick={() => this.setState({ isShowAll: true })}
                >
                  Load More
                </a>
              </div>
            </>
          ) : (
            <div className="list">
              {items.map((item) => (
                <Item item={item} key={Math.random()} />
              ))}
            </div>
          )}
        </>
      )
    );
  }
}

export default App;
