import React from 'react';
import ReactDOM from 'react-dom';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
// import './css/components.css';
// import './css/normalize.css';
// import './css/refind.css';
import Demo from './App';
import SupplierSlider from './SupplierSlider';

if (window.location.pathname === '/') {
  ReactDOM.render(<Demo />, document.getElementById('demo'));
} else if (window.location.pathname.match(/\/suppliers\/(.+)/)) {
  ReactDOM.render(<SupplierSlider />, document.getElementById('supplier-slider'));
}
